@use "mixins.scss" as *;
@use "fonts.scss";
@use "colours.scss";

body {
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5;
  background-color: var(--globalBg);
  color: var(--text);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @include mobile {
    padding: 0px;
  }
}

.content {
  width: calc(100% - 208px);
  max-width: 960px;
  background-color: var(--contentBg);
  margin: 16px;
  padding: 20px 88px 88px 88px;
  border-radius: 16px;
  font-size: 18px;
  letter-spacing: -1%;
  text-align: justify;
  @include mobile {
    width: calc(100% - 60px);
    margin: 12px;
    padding: 18px;
    text-align: initial;
  }
}
.logo {
  border-radius: 0 !important;
  padding: 26px 0;
  @include mobile {
    padding: 4px 0 32px 0;
  }
}
img {
  border-radius: 16px;
}
.float-right {
  float: right;
  margin-left: 40px;
  @include mobile {
    float: none;
    width: 146px;
    margin-left: 0;
  }
}
h1,
h2,
h3 {
  padding-top: 12px;
  letter-spacing: -4%;
  text-align: left;
}
h1 {
  font-weight: 700;
  font-size: 56px;
  @include mobile {
    font-size: 36px;
  }
}
h2 {
  font-weight: 600;
  font-size: 28px;
}
h3 {
  font-weight: 600;
  font-size: 24px;
}
.tags {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
.tag {
  padding: 4px 16px 6px 16px;
  border-radius: 100px;
  background-color: var(--tagBg);
  color: var(--tagText);
  font-weight: 600;
  font-size: 16px;
}
article {
  display: block;
}
article > * {
  margin: 4px 0;
}
ul {
  padding: 0;
}
li {
  margin: 5px 0;
  list-style-type: initial;
  list-style-position: inside;
}
a {
  color: var(--link);
  text-decoration: underline;
}
a:hover {
  opacity: 0.8;
}
a.external::after {
  margin-left: 0.5em;
  top: 0.05em;
  position: relative;
  content: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 1C1.89543 1 1 1.89543 1 3V11C1 12.1046 1.89543 13 3 13H11C12.1046 13 13 12.1046 13 11V8.5C13 8.22386 13.2239 8 13.5 8C13.7761 8 14 8.22386 14 8.5V11C14 12.6569 12.6569 14 11 14H3C1.34315 14 0 12.6569 0 11V3C0 1.34315 1.34315 0 3 0H5.5C5.77614 0 6 0.223858 6 0.5C6 0.776142 5.77614 1 5.5 1H3ZM8 0.5C8 0.223858 8.22386 0 8.5 0H13.5C13.7761 0 14 0.223858 14 0.5V5.5C14 5.77614 13.7761 6 13.5 6C13.2239 6 13 5.77614 13 5.5V1.70711L8.85355 5.85355C8.65829 6.04882 8.34171 6.04882 8.14645 5.85355C7.95118 5.65829 7.95118 5.34171 8.14645 5.14645L12.2929 1H8.5C8.22386 1 8 0.776142 8 0.5Z' fill='%23115EA3'/%3E%3C/svg%3E%0A");
  @include dark {
    content: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 1C1.89543 1 1 1.89543 1 3V11C1 12.1046 1.89543 13 3 13H11C12.1046 13 13 12.1046 13 11V8.5C13 8.22386 13.2239 8 13.5 8C13.7761 8 14 8.22386 14 8.5V11C14 12.6569 12.6569 14 11 14H3C1.34315 14 0 12.6569 0 11V3C0 1.34315 1.34315 0 3 0H5.5C5.77614 0 6 0.223858 6 0.5C6 0.776142 5.77614 1 5.5 1H3ZM8 0.5C8 0.223858 8.22386 0 8.5 0H13.5C13.7761 0 14 0.223858 14 0.5V5.5C14 5.77614 13.7761 6 13.5 6C13.2239 6 13 5.77614 13 5.5V1.70711L8.85355 5.85355C8.65829 6.04882 8.34171 6.04882 8.14645 5.85355C7.95118 5.65829 7.95118 5.34171 8.14645 5.14645L12.2929 1H8.5C8.22386 1 8 0.776142 8 0.5Z' fill='%237CC0FF'/%3E%3C/svg%3E%0A");
  }
}

.meta {
  color: var(--metaText);
}

.logo {
  &.dark {
    @include light {
      display: none;
    }
    @include dark {
      display: block;
    }
  }
  &.light {
    @include light {
      display: block;
    }
    @include dark {
      display: none;
    }
  }
}

.projects {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.project {
  background-color: var(--cardBg);
  border: var(--cardBorder);
  box-shadow: 0px 8px 12px -8px rgba(26, 26, 46, 0.5);
  border-radius: 16px;
  overflow: hidden;
  justify-content: space-between;
  display: inline-flex;
  overflow: hidden;
  align-items: stretch;
  @include mobile {
    flex-direction: column;
  }
  & * {
    margin: 0;
  }
  & > * {
    width: 50%;
    box-sizing: border-box;
    @include mobile {
      width: 100%;
    }
  }
  & > img {
    border-radius: 0;
    object-fit: cover;
    display: block;
    position: relative;
    margin: 0;
  }
  & > div {
    flex: 1 1 0;
    align-self: stretch;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    display: flex;
    gap: 22px;
    & > div {
      flex: 1 1 0;
      align-self: stretch;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      display: flex;
      gap: 6px;
      & > h4 {
        text-align: left;
      }
    }
  }
}
.button {
  align-self: stretch;
  padding-left: 52px;
  padding-right: 52px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--buttonText);
  background: var(--buttonBg);
  text-decoration: none;
  box-shadow: 0px 8px 12px -8px rgba(26, 26, 46, 0.5);
  border-radius: 16px;
  overflow: hidden;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  display: flex;
  transition-property: box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-out;
  border: var(--buttonBorder);
  @include mobile {
    padding-left: 16px;
    padding-right: 16px;
  }
  &.external::after {
    @include light {
      filter: saturate(0) brightness(5);
    }
    @include dark {
      filter: saturate(0) brightness(1.5);
    }
  }
  &:hover {
    opacity: 1;
    box-shadow: 0px 8px 24px -8px rgba(26, 26, 46, 0.5);
  }
}
