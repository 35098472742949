@use "sass:selector";
@use "sass:string";
@use "sass:list";

@mixin light {
  @media (prefers-color-scheme: "light") {
    @content;
  }
}

@mixin dark {
  @media (prefers-color-scheme: "dark") {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 768px) {
    @content;
  }
}
