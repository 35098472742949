.noto-sans-100 {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 100;
}

.noto-sans-200 {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 200;
}

.noto-sans-300 {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.noto-sans-400 {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.noto-sans-500 {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.noto-sans-600 {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.noto-sans-700 {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 700;
}

html {
  --text: #000;
  --globalBg: #e0e5f4;
  --link: #115ea3;
  --tagBg: #e0e5f4;
  --tagText: #525e83;
  --buttonBg: #5c52b4;
  --buttonText: #fff;
  --buttonBorder: 1px #393980 solid;
  --contentBg: #fff;
  --cardBg: #f8faff;
  --cardBorder: 1px #dedeec solid;
  --metaText: #999;
}

@media (prefers-color-scheme: dark) {
  html {
    --text: #fff;
    --globalBg: #1a1a2e;
    --link: #7cc0ff;
    --tagBg: var(--globalBg);
    --tagText: #c2c7e2;
    --buttonBg: #262058;
    --buttonText: #fff;
    --buttonBorder: 1px #4a4a74 solid;
    --contentBg: #2e2e4e;
    --cardBg: #38385c;
    --cardBorder: 1px #4f4f7e solid;
    --metaText: #88889b;
  }
}

body {
  background-color: var(--globalBg);
  color: var(--text);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-family: Noto Sans, sans-serif;
  line-height: 1.5;
  display: flex;
}

@media screen and (width <= 768px) {
  body {
    padding: 0;
  }
}

.content {
  background-color: var(--contentBg);
  width: calc(100% - 208px);
  max-width: 960px;
  letter-spacing: -1%;
  text-align: justify;
  border-radius: 16px;
  margin: 16px;
  padding: 20px 88px 88px;
  font-size: 18px;
}

@media screen and (width <= 768px) {
  .content {
    width: calc(100% - 60px);
    text-align: initial;
    margin: 12px;
    padding: 18px;
  }
}

.logo {
  padding: 26px 0;
  border-radius: 0 !important;
}

@media screen and (width <= 768px) {
  .logo {
    padding: 4px 0 32px;
  }
}

img {
  border-radius: 16px;
}

.float-right {
  float: right;
  margin-left: 40px;
}

@media screen and (width <= 768px) {
  .float-right {
    float: none;
    width: 146px;
    margin-left: 0;
  }
}

h1, h2, h3 {
  letter-spacing: -4%;
  text-align: left;
  padding-top: 12px;
}

h1 {
  font-size: 56px;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  h1 {
    font-size: 36px;
  }
}

h2 {
  font-size: 28px;
  font-weight: 600;
}

h3 {
  font-size: 24px;
  font-weight: 600;
}

.tags {
  flex-wrap: wrap;
  gap: 12px;
  display: flex;
}

.tag {
  background-color: var(--tagBg);
  color: var(--tagText);
  border-radius: 100px;
  padding: 4px 16px 6px;
  font-size: 16px;
  font-weight: 600;
}

article {
  display: block;
}

article > * {
  margin: 4px 0;
}

ul {
  padding: 0;
}

li {
  list-style-type: initial;
  margin: 5px 0;
  list-style-position: inside;
}

a {
  color: var(--link);
  text-decoration: underline;
}

a:hover {
  opacity: .8;
}

a.external:after {
  content: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 1C1.89543 1 1 1.89543 1 3V11C1 12.1046 1.89543 13 3 13H11C12.1046 13 13 12.1046 13 11V8.5C13 8.22386 13.2239 8 13.5 8C13.7761 8 14 8.22386 14 8.5V11C14 12.6569 12.6569 14 11 14H3C1.34315 14 0 12.6569 0 11V3C0 1.34315 1.34315 0 3 0H5.5C5.77614 0 6 0.223858 6 0.5C6 0.776142 5.77614 1 5.5 1H3ZM8 0.5C8 0.223858 8.22386 0 8.5 0H13.5C13.7761 0 14 0.223858 14 0.5V5.5C14 5.77614 13.7761 6 13.5 6C13.2239 6 13 5.77614 13 5.5V1.70711L8.85355 5.85355C8.65829 6.04882 8.34171 6.04882 8.14645 5.85355C7.95118 5.65829 7.95118 5.34171 8.14645 5.14645L12.2929 1H8.5C8.22386 1 8 0.776142 8 0.5Z' fill='%23115EA3'/%3E%3C/svg%3E%0A");
  margin-left: .5em;
  position: relative;
  top: .05em;
}

@media (prefers-color-scheme: dark) {
  a.external:after {
    content: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 1C1.89543 1 1 1.89543 1 3V11C1 12.1046 1.89543 13 3 13H11C12.1046 13 13 12.1046 13 11V8.5C13 8.22386 13.2239 8 13.5 8C13.7761 8 14 8.22386 14 8.5V11C14 12.6569 12.6569 14 11 14H3C1.34315 14 0 12.6569 0 11V3C0 1.34315 1.34315 0 3 0H5.5C5.77614 0 6 0.223858 6 0.5C6 0.776142 5.77614 1 5.5 1H3ZM8 0.5C8 0.223858 8.22386 0 8.5 0H13.5C13.7761 0 14 0.223858 14 0.5V5.5C14 5.77614 13.7761 6 13.5 6C13.2239 6 13 5.77614 13 5.5V1.70711L8.85355 5.85355C8.65829 6.04882 8.34171 6.04882 8.14645 5.85355C7.95118 5.65829 7.95118 5.34171 8.14645 5.14645L12.2929 1H8.5C8.22386 1 8 0.776142 8 0.5Z' fill='%237CC0FF'/%3E%3C/svg%3E%0A");
  }
}

.meta {
  color: var(--metaText);
}

@media (prefers-color-scheme: light) {
  .logo.dark {
    display: none;
  }
}

@media (prefers-color-scheme: dark) {
  .logo.dark {
    display: block;
  }
}

@media (prefers-color-scheme: light) {
  .logo.light {
    display: block;
  }
}

@media (prefers-color-scheme: dark) {
  .logo.light {
    display: none;
  }
}

.projects {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.project {
  background-color: var(--cardBg);
  border: var(--cardBorder);
  border-radius: 16px;
  justify-content: space-between;
  align-items: stretch;
  display: inline-flex;
  overflow: hidden;
  box-shadow: 0 8px 12px -8px #1a1a2e80;
}

@media screen and (width <= 768px) {
  .project {
    flex-direction: column;
  }
}

.project * {
  margin: 0;
}

.project > * {
  box-sizing: border-box;
  width: 50%;
}

@media screen and (width <= 768px) {
  .project > * {
    width: 100%;
  }
}

.project > img {
  object-fit: cover;
  border-radius: 0;
  margin: 0;
  display: block;
  position: relative;
}

.project > div {
  flex-direction: column;
  flex: 1 1 0;
  justify-content: space-between;
  align-self: stretch;
  gap: 22px;
  padding: 20px;
  display: flex;
  position: relative;
}

.project > div > div {
  flex-direction: column;
  flex: 1 1 0;
  justify-content: space-between;
  align-self: stretch;
  gap: 6px;
  display: flex;
  position: relative;
}

.project > div > div > h4 {
  text-align: left;
}

.button {
  color: var(--buttonText);
  background: var(--buttonBg);
  border: var(--buttonBorder);
  border-radius: 16px;
  justify-content: center;
  align-self: stretch;
  align-items: flex-end;
  gap: 10px;
  padding: 10px 52px;
  text-decoration: none;
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: ease-out;
  display: flex;
  overflow: hidden;
  box-shadow: 0 8px 12px -8px #1a1a2e80;
}

@media screen and (width <= 768px) {
  .button {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (prefers-color-scheme: light) {
  .button.external:after {
    filter: saturate(0) brightness(5);
  }
}

@media (prefers-color-scheme: dark) {
  .button.external:after {
    filter: saturate(0) brightness(1.5);
  }
}

.button:hover {
  opacity: 1;
  box-shadow: 0 8px 24px -8px #1a1a2e80;
}
/*# sourceMappingURL=index.aa1a8b4b.css.map */
