html {
  --text: #000000;
  --globalBg: #e0e5f4;
  --link: #115ea3;
  --tagBg: #e0e5f4;
  --tagText: #525e83;
  --buttonBg: #5c52b4;
  --buttonText: #ffffff;
  --buttonBorder: 1px #393980 solid;
  --contentBg: #ffffff;
  --cardBg: #f8faff;
  --cardBorder: 1px #dedeec solid;
  --metaText: #999;
  @media (prefers-color-scheme: dark) {
    --text: #ffffff;
    --globalBg: #1a1a2e;
    --link: #7cc0ff;
    --tagBg: var(--globalBg);
    --tagText: #c2c7e2;
    --buttonBg: #262058;
    --buttonText: #ffffff;
    --buttonBorder: 1px #4a4a74 solid;
    --contentBg: #2e2e4e;
    --cardBg: #38385c;
    --cardBorder: 1px #4f4f7e solid;
    --metaText: #88889b;
  }
}
